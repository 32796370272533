.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    margin: 0 0 10px;
    padding: 0 24px 24px 24px;
    background: white;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.15);
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 0;
    margin: 0 12px;
    cursor: pointer;

    &--selected {
      background: #fff;
      border-bottom: 1px solid gray;
      color: black;
    }

    &--disabled {
      color: gray;
      cursor: default;
    }

    &:hover {
      border-bottom: 1px solid gray;
    }

    &:focus {
      box-shadow: 0 0 5px hsl(208, 99%, 50%);
      border-color: hsl(208, 99%, 50%);
      outline: none;
    }
  }

  &__tab-panel {
    display: none;
    padding: 24px;

    &--selected {
      display: block;
    }
  }
}
