.tag {
	letter-spacing: .025em;
	color: rgba(255,255,255, 1);
	background-color: #1a202c;
	box-sizing: border-box;
	line-height: 0.8rem;

	font-size: 85%;
	overflow: hidden;
	padding: 3px 6px;
	margin: 2px;
	text-overflow: ellipsis;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;


	&:focus-within {
		border-color: darken($color: #d2d6dc, $amount: 0.2);
	}
}

.tag-remove {
	color: white;
	font-size: 75%;
	line-height: 100%;
	cursor: pointer;
	background-color: transparent;
	border: none;
	outline: none;
	margin-left: 10px;
  
	&:focus {
		color: #fefefe;
	}
}
.tag-remove.disabled,.tag-remove.readOnly {
	cursor: not-allowed;
}

.node {
	list-style: none;
	white-space: nowrap;
  padding: 10px;
  display: flex;
  align-items: center;
  
	& > label {
		cursor: pointer;
    margin-left: 2px;
    display: flex;
    align-items: center;
  }
  
	&.leaf {
		&.collapsed {
			display: none;
		}
  }
  
	&.disabled {
		& > * {
			color: gray;
			cursor: not-allowed;
		}
  }
  
	&.match-in-children {
		&.hide {
			.node-label {
				opacity: .5;
			}
		}
  }
}

.tag-list {
	display: inline;
	box-sizing: content-box;
	padding: 0;
	margin: 0;
	margin-right: 25px;
}

.tag-item {
	display: inline-block;
	border-radius: 2px;
	min-width: 0;
	box-sizing: border-box;

	&.special {
		margin-right: 10px;
	}

	&:last-child {
		&:not(:only-child) {
			display: none;
		}
	}
  
	.search {
		box-sizing: border-box;
		border: none;
		outline: none;
  }
  
}

.toggle {
	white-space: pre;
	margin-right: 10px;
  cursor: pointer;
  position: relative;
  display: block;
  height: 8px;
  width: 14px;

  &:focus {
    outline: none;
  }

  &:before,
  &:after {
      position: absolute;
      display: block;
      content: "";
      border: 7px solid transparent;/*adjust size*/
  }
  
	&.collapsed {
    &:before {
      top: 0;
      border-top-color: currentColor;/*Chevron Color*/
    }
    
    &:after {
      top: -2px;/*adjust thickness*/
      border-top-color: #fff;/*Match background colour*/
    }
  }
  
	&.expanded {
		&:before {
      bottom: 0;
      border-bottom-color: currentColor;/*Chevron Color*/
    }
    
    &:after {
      bottom: -2px;/*adjust thickness*/
      border-bottom-color: #fff;/*Match background colour*/
    }
	}
}
.searchModeOn {
	.toggle {
		display: none;
	}
}
.checkbox-item,.radio-item {
	vertical-align: middle;
	margin: 0 10px 0 0;
}
.checkbox-item.simple-select,.radio-item.simple-select {
	display: none;
}
.hide {
	&:not(.match-in-children) {
		display: none;
	}
}
.react-dropdown-tree-select {
	.dropdown {
		position: relative;
		display: table;
		width: 100%;
    
		.dropdown-trigger {
			line-height: 20px;
			max-height: 200px;
			display: inline-block;
			overflow: auto;
			
			&:focus {
				outline: none;
			}
      
			&.arrow {
        cursor: pointer;
        
				&.bottom {
					cursor: pointer;
					position: relative;
					display: block;
					transition: all 0.2s;

					&:before {
							position: absolute;
							display: block;
							content: ' ';
							background-image: url(../../../resources/images/icons/arrow_down.svg);
							background-size: 20px 20px;
							height: 20px;
							width: 20px;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
					}
        }
        
				&.top {
					cursor: pointer;
					position: relative;
					display: block;
					transition: all 0.2s;

					&:before {
						position: absolute;
						display: block;
						content: ' ';
						background-image: url(../../../resources/images/icons/arrow_down.svg);
						transform: translateY(-50%) rotate(180deg);
						background-size: 20px 20px;
						height: 20px;
						width: 20px;
						right: 0;
						top: 50%;
				}
        }
        
				&.disabled {
					cursor: not-allowed;
					&.bottom {
						&:after {
							color: #b9b9b9;
						}
					}
				}
			}
		}
		.dropdown-content {
			position: absolute;
			padding: 16px;
			z-index: 1;
			background: #fff;
			border-top: 1px solid rgba(0,0,0,.05);
			-webkit-box-shadow: 0 5px 8px rgba(0,0,0,.15);
			box-shadow: 0 5px 8px rgba(0,0,0,.15);
			border-radius: 4px;
      
			.search {
				box-sizing: border-box;
				width: 100%;
				border: none;
				outline: none;
      }
      
			ul {
				margin: 0;
				padding: 0;
			}
		}
	}
}
